import * as React from "react";
import { Link } from "gatsby";
import Button from "../Button/Button";
import H2 from "../H2/H2";

const Alumnus = ({ nodes }: any) => {
  return (
    <>
      <ul className="md:tw-flex md:tw-flex-wrap">
        {nodes.map((node: any, index: any) => {
          const url = node.fields.slug;
          return (
            <li
              key={index}
              className="tw-flex tw-pb-6 last:tw-pb-0 md:tw-w-1/2 md:tw-pr-8 md:tw-pb-12"
            >
              <div className="tw-w-1/4 tw-mr-6">
                <Link to={url}>
                  <img
                    src={node.frontmatter.ce_thumbnail}
                    alt=""
                    className="tw-rounded-full"
                  />
                </Link>
              </div>
              <div className="tw-w-3/4">
                <div className="tw-flex tw-justify-between tw-items-baseline">
                  <p className="tw-text-lg tw-font-bold md:tw-text-2xl">
                    <Link to={url}>{node.frontmatter.ce_title}</Link>
                  </p>
                  <Button url={url} name="MORE"></Button>
                </div>
                <p className="tw-mt-1 tw-text-baseDeepGray tw-text-xs md:tw-text-base">
                  @{node.frontmatter.ce_label}
                </p>
                <p className="tw-mt-1 tw-text-sm md:tw-text-xl">
                  {node.frontmatter.ce_description}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Alumnus;
