import React from "react";

interface ImageProps {
  classNames: string;
  alt: string;
  src: string;
}

/**
 * 画像クラス
 * 
 * 指定するクラス
 * !tw-w-oneQuarter : 25%
 * !tw-w-threeCol : 33%
 * !tw-w-half : 50%
 * !tw-w-threeQuarter : 75%
 * !tw-w-full : 100%
 */
export const Image = ({ classNames, alt, src }: ImageProps) => {
  return (
    <p>
      <img src={src} alt={alt} className={classNames} />
    </p>
  );
};
