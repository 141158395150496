import * as React from "react";
import { Link } from "gatsby";
import H2 from "../../elements/H2/H2";
import { shuffle } from "../../../utils/shuffle/shuffle";

function randomClass() {
  return Math.random() > 0.5;
}

function getItemLink(nodes: any, tag: any) {
  const items = nodes.filter((node: any) =>
    node.frontmatter.ce_tags.includes(tag)
  );
  return items.length > 0 ? shuffle(items)[0].fields.slug : null;
}

const Tags = ({ nodes, tags }: any) => {
  const shuffledTags = shuffle(tags);
  return (
    <div className="tw-pt-6 tw-px-4 tw-pb-12 md:tw-px-0 md:tw-py-16">
      <H2 title="この中にあなたがいる？" />
      <ul className="tw-flex tw-justify-center tw-items-baseline tw-flex-wrap tw-mt-4">
        {shuffledTags.map((tag, index) => {
          const url = getItemLink(nodes, tag.id);
          return (
            url && (
              <li
                key={index}
                className={`tw-inline-block tw-mr-4 tw-mt-4 tw-border-2 tw-border-baseBlue tw-rounded-full tw-text-baseBlue md:tw-mt-8 ${
                  randomClass()
                    ? "tw-px-5 tw-py-1 tw-text-xs md:tw-text-base"
                    : "tw-px-5 tw-py-2 tw-text-lg md:tw-text-2xl"
                }`}
              >
                <Link to={url}># {tag.ja}</Link>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default Tags;
