import React from "react";
import { Spacer } from "../Spacer/Spacer";

interface Props {
  title: string;
  spaceTop: boolean;
  spaceBottom: boolean;
}

/**
 * MDX のための出力テンプレート用Body
 *
 * NOTE: この要素以下が自動でh1などのスタイルがつく
 *
 * @param param0
 * @returns
 */
export const HtmlBody = ({
  children,
  title,
  spaceTop,
  spaceBottom,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="tw-c-html-body md:tw-c-html-body-md">
      {spaceTop ? <Spacer type="main-top" /> : null}
      {children}
      {spaceBottom ? <Spacer type="main-bottom" /> : null}
    </div>
  );
};
