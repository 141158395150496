import * as React from "react";
import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";
import { useGakka } from "../../../hooks/useGakka/useGakka";

const PageHeader = ({ title, leadSentence, backgroundColor }: any) => {
  const gakka = useGakka();
  const bgColor = backgroundColor ? backgroundColor : gakka.color;
  return (
    <ResponsiveBlock bgColor={`tw-bg-${bgColor}`}>
      <div
        className={`tw-px-4 tw-py-6 tw-bg-${bgColor} tw-text-baseWhite md:tw-px-0 md:tw-py-12`}
      >
        <h1 className="tw-pl-3 tw-border-l-4 tw-border-baseWhite tw-text-htmlH1 tw-font-black md:tw-border-l-8 md:tw-text-htmlH1pc">
          {title}
        </h1>
        {leadSentence && (
          <p className="tw-mt-3 tw-text-xs md:tw-text-base">{leadSentence}</p>
        )}
      </div>
    </ResponsiveBlock>
  );
};

/**
 * カレンダー等の学科名を含む2行のヘッダー
 */
export const PageHeaderWithGakka = ({ title, invertTitle = false }: any) => {
  const gakka = useGakka();
  return (
    <ResponsiveBlock bgColor={`tw-bg-${gakka.color}`}>
      <div
        className={`tw-px-4 tw-py-6 tw-bg-${gakka.color} tw-text-baseWhite md:tw-px-0 md:tw-py-12`}
      >
        <h1 className="tw-pl-3 tw-border-l-4 tw-border-baseWhite tw-text-htmlH1 tw-font-black md:tw-border-l-8 md:tw-text-htmlH1pc">
          {invertTitle ? (
            <>
              {title}
              <br />
              {gakka.title}
            </>
          ) : (
            <>
              {gakka.title}
              <br />
              {title}
            </>
          )}
        </h1>
      </div>
    </ResponsiveBlock>
  );
};

export default PageHeader;
