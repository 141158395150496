import * as React from "react";
import { Link } from "gatsby";
import { BsInstagram } from "react-icons/bs";
import {
  BookmarkSquareIcon,
  CalendarDaysIcon,
  ChartPieIcon,
} from "@heroicons/react/24/outline";
import H2 from "../H2/H2";
import { ResponsiveBlock } from "../../layouts/ResponsiveBlock/ResponsiveBlock";
import { useGakka } from "../../../hooks/useGakka/useGakka";

const DivisionAbstruct = ({ spaceBottom = false }) => {
  const gakka = useGakka();
  return (
    <ResponsiveBlock bgColor="">
      <div
        className={`tw-px-4 tw-py-8 md:tw-px-0 md:tw-py-16${
          spaceBottom ? " tw-pb-16 md:tw-pb-32" : ""
        }`}
      >
        <H2 title="学科概要" />
        <ul className="tw-px-6">
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <BookmarkSquareIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/admissions/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                こんな人に学んでほしい
              </Link>
            </div>
          </li>
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <CalendarDaysIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/study/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                2年間の学び
              </Link>
            </div>
          </li>
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <ChartPieIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/employment/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                就職実績
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </ResponsiveBlock>
  );
};

export const KoreanDivisionAbstruct = ({ spaceBottom = false }) => {
  const gakka = useGakka();
  return (
    <ResponsiveBlock bgColor="">
      <div
        className={`tw-px-4 tw-py-8 md:tw-px-0 md:tw-py-16${
          spaceBottom ? " tw-pb-16 md:tw-pb-32" : ""
        }`}
      >
        <H2 title="学科概要" />
        <ul className="tw-px-6">
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <BookmarkSquareIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/admissions/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                こんな人に学んでほしい
              </Link>
            </div>
          </li>
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <CalendarDaysIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/study/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                韓国語専攻の卒業生たち
              </Link>
            </div>
          </li>
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-mr-4 md:tw-mr-6">
              <ChartPieIcon className="tw-w-6 tw-h-6 md:tw-w-9 md:tw-h-9" />
            </div>
            <div>
              <Link
                to={`${gakka.link}/employment/`}
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                韓国大学編入・就職実績
              </Link>
            </div>
          </li>
          <li className="tw-flex tw-mt-7 md:tw-mt-10">
            <div className="tw-ml-[2px] tw-mr-4 md:tw-mr-6">
              <BsInstagram className="tw-w-5 tw-h-5 md:tw-w-8 md:tw-h-8" />
            </div>
            <div>
              <a
                href="https://www.instagram.com/jcfl_ae_korean/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
                target="_blank"
                className="tw-text-base tw-underline md:tw-text-2xl"
              >
                韓国語専攻の最新情報をチェック
              </a>
            </div>
          </li>
        </ul>
      </div>
    </ResponsiveBlock>
  );
};

export default DivisionAbstruct;
